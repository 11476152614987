// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
  {
    id: 1,
    name: "Al-Qimah",
    title: "Al-Qimah Training Center",
    category: "Web Application",
    img: require("@/assets/images/projects/project-qtc-2.png"),
    date: "Jul 26, 2021",
    projectImages: [
      {
        id: 1,
        title: "Al-Qimah Training Center System",
        img: require("@/assets/images/projects/project-qtc-2.png"),
      },
      {
        id: 2,
        title: "Kabul Project Management UI",
        img: require("@/assets/images/projects/project-qtc-3.png"),
      },
      {
        id: 3,
        title: "Kabul Project Management UI",
        img: require("@/assets/images/projects/project-qtc-1.png"),
      },
    ],
    projectInfo: {
      clientHeading: "About Client",
      companyInfos: [
        {
          id: 1,
          title: "Name",
          details: "Al-Qimah TC",
        },
        {
          id: 2,
          title: "Services",
          details: "Students Tarining & Education",
        },
        {
          id: 3,
          title: "Website",
          details: "https://alqimah.com",
        },
        {
          id: 4,
          title: "Phone",
          details: "926662255",
        },
      ],
      objectivesHeading: "Objective",
      objectivesDetails:
        "Help the managing staff to moderate the center and their students payments and courses mangament.",
      technologies: [
        {
          title: "Tools & Technologies",
          techs: [
            "HTML",
            "CSS",
            "JavaScript",
            "Vue.js",
            "Bootstrap",
            "Laravel",
            "MySql",
          ],
        },
      ],
      projectDetailsHeading: "Challenge",
      projectDetails: [
        {
          id: 1,
          details:
            "Data management: Collecting, storing, and processing large amounts of data can be challenging. Ensuring that the data is accurate, up-to-date, and secure is also important.",
        },
        {
          id: 2,
          details:
            "User adoption: Encouraging users to adopt the new system can be challenging. Providing training and support can help users become familiar with the system and increase adoption rates.",
        },
        {
          id: 3,
          details:
            "Maintenance: Maintaining the system can be time-consuming and expensive. Ensuring that the system is updated regularly and that any issues are resolved quickly is important.",
        },
        {
          id: 4,
          details:
            "Security: Ensuring that the system is secure and that sensitive data is protected is crucial. Implementing security measures such as encryption, access controls, and firewalls can help protect the system from cyber threats.",
        },
      ],
    },
  },
  {
    id: 2,
    name: "ES&EMS",
    title: "University Study & Exams Management System",
    category: "Web Application",
    img: require("@/assets/images/projects/project-esems-3.png"),
    date: "Jul 26, 2021",
    projectImages: [
      {
        id: 1,
        title: "University Study & Exams Management System",
        img: require("@/assets/images/projects/project-esems-1.png"),
      },
      {
        id: 2,
        title: "University Study & Exams Management System",
        img: require("@/assets/images/projects/project-esems-2.png"),
      },
      {
        id: 3,
        title: "University Study & Exams Management System",
        img: require("@/assets/images/projects/project-esems-3.png"),
      },
      {
        id: 4,
        title: "University Study & Exams Management System",
        img: require("@/assets/images/projects/project-esems-4.png"),
      },
      {
        id: 5,
        title: "University Study & Exams Management System",
        img: require("@/assets/images/projects/project-esems-5.png"),
      },
    ],
    projectInfo: {
      clientHeading: "About Client",
      companyInfos: [
        {
          id: 1,
          title: "Name",
          details: "University System",
        },
        {
          id: 2,
          title: "Services",
          details: "Study & Exams Management",
        },
        {
          id: 3,
          title: "Website",
          details: "https://system.khudari.ly",
        },
        {
          id: 4,
          title: "Phone",
          details: "928123080",
        },
      ],
      objectivesHeading: "Objective",
      objectivesDetails:
        "Assist the management staff in overseeing the academic operations, including managing student enrollment, payments, and course schedules.",
      technologies: [
        {
          title: "Tools & Technologies",
          techs: [
            "HTML",
            "CSS",
            "JavaScript",
            "Bootstrap",
            "Laravel BLADE",
            "MySql",
          ],
        },
      ],
      projectDetailsHeading: "Challenge",
      projectDetails: [
        {
          id: 1,
          details:
            "Data management: Collecting, storing, and processing large amounts of data can be challenging. Ensuring that the data is accurate, up-to-date, and secure is also important.",
        },
        {
          id: 2,
          details:
            "User adoption: Encouraging users to adopt the new system can be challenging. Providing training and support can help users become familiar with the system and increase adoption rates.",
        },
        {
          id: 3,
          details:
            "Maintenance: Maintaining the system can be time-consuming and expensive. Ensuring that the system is updated regularly and that any issues are resolved quickly is important.",
        },
        {
          id: 4,
          details:
            "Security: Ensuring that the system is secure and that sensitive data is protected is crucial. Implementing security measures such as encryption, access controls, and firewalls can help protect the system from cyber threats.",
        },
      ],
    },
  },
];

export default projects;
