export const socialLinks = [
  {
    id: 1,
    name: "Website",
    icon: "globe",
    url: "https://www.khudari.ly/",
  },
  {
    id: 2,
    name: "GitHub",
    icon: "github",
    url: "https://github.com/alkhedri",
  },
  {
    id: 3,
    name: "x",
    icon: "x",
    url: "https://x.com/mkhuDari98",
  },
  {
    id: 4,
    name: "LinkedIn",
    icon: "linkedin",
    url: "https://www.linkedin.com/in/mohammed-khudari-5518452a6/",
  },
];
