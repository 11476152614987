export default {
  namespaced: true,
  state: {
    singleProjectTitle: "ProjectTitle x",
    singleProjectDate: "ProjectDate z",
    singleProjectTag: "ProjectTag z",
    projectImages: [
      {
        id: 1,
        title: "Al-Qimah Training Center System",
        img: require("@/assets/images/web-project-1.jpg"),
      },
      {
        id: 2,
        title: "Al-Qimah Training Center System",
        img: require("@/assets/images/web-project-2.jpg"),
      },
      {
        id: 3,
        title: "Al-Qimah Training Center System",
        img: require("@/assets/images/mobile-project-2.jpg"),
      },
    ],
    projectInfo: {
      clientHeading: "About Client",
      companyInfos: [
        {
          id: 1,
          title: "Name",
          details: "Company Ltd",
        },
        {
          id: 2,
          title: "Services",
          details: "UI Design & Frontend Development",
        },
        {
          id: 3,
          title: "Website",
          details: "https://company.com",
        },
        {
          id: 4,
          title: "Phone",
          details: "555 8888 888",
        },
      ],
      objectivesHeading: "Objective",
      objectivesDetails:
        "Assist the management team in overseeing the center’s operations, including managing student payments and course schedules.",
      technologies: [
        {
          title: "Tools & Technologies",
          techs: [
            "HTML",
            "CSS",
            "JavaScript",
            "Vue.js",
            "Bootstrap",
            "Laravel",
            "MySql",
          ],
        },
      ],
      projectDetailsHeading: "Challenge",
      projectDetails: [
        {
          id: 1,
          details:
            "L cabo, eaque co unt to ciatis! Aperiam perferendis labore temporibus, eos culpa corporis recusandae quas, fuga voluptatibus nesciunt odit libero tenetur neque consequatur ea.",
        },
        {
          id: 2,
          details:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil vel illum asperiores dignissimos cumque quibusdam et fugiat voluptatem nobis suscipit explicabo, eaque consequatur nesciunt, fugit eligendi corporis laudantium adipisci soluta?",
        },
        {
          id: 3,
          details:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil vel illum asperiores dignissimos cumque quibusdam et fugiat voluptatem nobis suscipit explicabo, eaque consequatur nesciunt, fugit eligendi corporis laudantium adipisci soluta?",
        },
        {
          id: 4,
          details:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil vel illum asperiores dignissimos cumque quibusdam et fugiat voluptatem nobis suscipit explicabo, eaque consequatur nesciunt, fugit eligendi corporis laudantium adipisci soluta? Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt totam dolorum, ducimus obcaecati, voluptas facilis molestias nobis ut quam natus similique inventore excepturi optio ipsa deleniti fugit illo. Unde, amet! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum illo necessitatibus perspiciatis! Aperiam perferendis labore temporibus, eos culpa corporis recusandae quas, fuga voluptatibus nesciunt odit libero tenetur neque consequatur ea.",
        },
      ],
    },
  },
  getters: {},
  mutations: {},
  actions: {},
};
