import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/app.css";
import BackToTop from "vue-backtotop";
import VueWriter from "vue-writer";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
const feather = require("feather-icons");
feather.replace();

createApp(App)
  .use(router)
  .use(store)
  .use(BackToTop)
  .use(VueWriter)
  .component("VueperSlides", VueperSlides)
  .component("VueperSlide", VueperSlide)
  .mount("#app");

const appTheme = localStorage.getItem("theme");

// Check what is the active theme and change theme when user clicks on the theme button in header.
if (
  appTheme === "dark" &&
  document.querySelector("body").classList.contains("app-theme")
) {
  document.querySelector("body").classList.add("bg-primary-dark");
} else {
  document.querySelector("body").classList.add("bg-secondary-light");
}
