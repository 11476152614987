import { createStore } from "vuex";

import createPersistedState from "vuex-persistedstate";
import data from "../store/projectsData";

export default createStore({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  modules: {
    data,
  },
});
