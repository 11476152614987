<script>
//import store from "../../store";

export default {
  props: ["project"],

  methods: {
    saveStorage(key, data) {
      localStorage.setItem(key, JSON.stringify(data));
    },

    grabProjectData() {
      this.saveStorage("title", this.project.title);
      this.saveStorage("date", this.project.date);
      this.saveStorage("tag", this.project.category);
      this.saveStorage("projectInfo", this.project.projectInfo);
      this.saveStorage("projectImages", this.project.projectImages);
      // store.state.data.singleProjectTitle = this.project.title;
      // store.state.data.singleProjectDate = this.project.date;
      // store.state.data.singleProjectTag = this.project.category;

      // store.state.data.projectImages = this.project.projectImages;
      // store.state.data.projectInfo = this.project.projectInfo;
    },
  },
};
</script>

<template>
  <router-link
    @click="grabProjectData()"
    :to="{
      name: 'Single Project',
    }"
    class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
    aria-label="Single Project"
  >
    <div>
      <img
        :src="project.img"
        :alt="project.title"
        class="rounded-t-xl border-none"
      />
    </div>
    <div class="text-center px-4 py-6">
      <p
        class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
      >
        {{ project.title }}
      </p>
      <span
        class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
        >{{ project.category }}</span
      >
    </div>
  </router-link>
</template>

<style lang="scss" scoped></style>
